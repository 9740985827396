import { Elm } from './src/Main.elm';
import './scss/styles.scss';
import { initialise } from '../../common/Elm/bootstrapElm';
import { getTranslationRequestObject } from '../../common/utils/getTranslationRequestObject';
import {
    countries,
    genders,
    departments,
    seniorities,
} from '../../common/utils/constantsJs';

const { bootstrap, mount, unmount } = initialise([{
    elmModule: Elm.Main,
    domGetter: () => document.getElementById('app-accountsettings'),
    externalResources: getTranslationRequestObject([
        ['countries', countries],
        ['genders', genders],
        ['departments', departments],
        ['seniorities', seniorities],
    ]),
}]);

export {
    bootstrap,
    mount,
    unmount,
};
